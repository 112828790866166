import { useTranslation } from 'react-i18next'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { userCountry } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { countryId } from 'srs.sharedcomponents/lib/esm/utils/enums'

const NetPriceIndicatorComponent = () => {
    const { t } = useTranslation('')
    const country = useAppSelector(userCountry)

    const showNetPriceLabel = () => {
        return country && countryId[country] == 'BR';
    }

    if (showNetPriceLabel())
    {
        return (
            <div className="font-sm fw-bold text-danger">
                {t(`txtBrazilNote`, { ns: namespace.srsPrimary })}
            </div>
        )
    }
    else return <></>
}

export default NetPriceIndicatorComponent