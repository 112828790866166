import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import * as namespaces from 'srs.sharedcomponents/lib/esm/i18n/locales'
import * as namespacesPrimary from './locales'


export const i18nNamespaces = {
  coreSrsImages: 'coreSrsImages',
  coreSrsPages: 'coreSrsPages',
  coreSrsPrimary: 'coreSrsPrimary',
  editorLocalization: 'editorLocalization',
  gridLocalization: 'gridLocalization',
  srsImages: 'srsImages',
  srsPages: 'srsPages',
  srsPrimary: 'srsPrimary',
  uploadLocalization: 'uploadLocalization',
  srsCookies: 'srsCookies',
}
const resources = {
  bg_BG: {
    editorLocalization: namespaces.bg_BG_EditorLocalization,
    gridLocalization: namespaces.bg_BG_GridLocalization,
    uploadLocalization: namespaces.bg_BG_UploadLocalization,
  },
  cs_CZ: {
    coreSrsImages: namespaces.cs_CZ_CoreSrsImages,
    coreSrsPrimary: namespaces.cs_CZ_CoreSrsPrimary,
  },
  da_DK: {
    coreSrsImages: namespaces.da_DK_CoreSrsImages,
    coreSrsPrimary: namespaces.da_DK_CoreSrsPrimary,
    editorLocalization: namespaces.da_DK_EditorLocalization,
    gridLocalization: namespaces.da_DK_GridLocalization,
    uploadLocalization: namespaces.da_DK_UploadLocalization,
  },
  de_DE: {
    srsPrimary: namespacesPrimary.de_DE_srsPrimary,
    coreSrsImages: namespaces.de_DE_CoreSrsImages,
    coreSrsPrimary: namespaces.de_DE_CoreSrsPrimary,
    editorLocalization: namespaces.de_DE_EditorLocalization,
    gridLocalization: namespaces.de_DE_GridLocalization,
  },
  el_GR: {
    coreSrsImages: namespaces.el_GR_CoreSrsImages,
    coreSrsPrimary: namespaces.el_GR_CoreSrsPrimary,
  },
  en_CA: {
    coreSrsImages: namespaces.en_US_CoreSrsImages,
    coreSrsPages: namespaces.en_US_CoreSrsPages,
    coreSrsPrimary: namespaces.en_US_CoreSrsPrimary,
    editorLocalization: namespaces.en_US_EditorLocalization,
    gridLocalization: namespaces.en_US_GridLocalization,
    srsImages: namespaces.en_US_srsImages,
    srsPages: namespaces.en_US_srsPages,
    srsPrimary: namespaces.en_US_srsPrimary,
    uploadLocalization: namespaces.en_US_UploadLocalization,
    srsCookies: namespaces.en_US_srsCookies,
  },
  en_US: {
    srsPrimary: namespacesPrimary.en_US_srsPrimary,
    coreSrsPrimary: namespaces.en_US_CoreSrsPrimary,
    coreSrsImages: namespaces.en_US_CoreSrsImages,
    coreSrsPages: namespaces.en_US_CoreSrsPages,
    editorLocalization: namespaces.en_US_EditorLocalization,
    gridLocalization: namespaces.en_US_GridLocalization,
    srsImages: namespaces.en_US_srsImages,
    srsPages: namespaces.en_US_srsPages,
    uploadLocalization: namespaces.en_US_UploadLocalization,
    srsCookies: namespaces.en_US_srsCookies,
  },
  es_ES: {
    coreSrsImages: namespaces.es_ES_CoreSrsImages,
    coreSrsPrimary: namespaces.es_ES_CoreSrsPrimary,
    gridLocalization: namespaces.es_ES_GridLocalization,
    srsPrimary: namespacesPrimary.es_ES_srsPrimary
  },
  es_MX: {
    coreSrsImages: namespaces.es_MX_CoreSrsImages,
    coreSrsPrimary: namespaces.es_MX_CoreSrsPrimary,
  },
  fi_FI: {
    coreSrsImages: namespaces.fi_FI_CoreSrsImages,
    coreSrsPrimary: namespaces.fi_FI_CoreSrsPrimary,
  },
  fr_CA: {
    coreSrsImages: namespaces.fr_CA_CoreSrsImages,
    coreSrsPrimary: namespaces.fr_CA_CoreSrsPrimary,
    gridLocalization: namespaces.fr_CA_GridLocalization,
    srsImages: namespaces.fr_CA_SrsImages,
    srsPrimary: namespacesPrimary.fr_CA_SrsPrimary,
    srsCookies: namespaces.fr_CA_srsCookies,
  },
  fr_FR: {
    coreSrsImages: namespaces.fr_FR_CoreSrsImages,
    coreSrsPrimary: namespaces.fr_FR_CoreSrsPrimary,
    editorLocalization: namespaces.fr_FR_EditorLocalization,
    gridLocalization: namespaces.fr_FR_GridLocalization,
    uploadLocalization: namespaces.fr_FR_UploadLocalization,
    srsPrimary: namespacesPrimary.fr_FR_srsPrimary,
  },
  hr_HR: {
    coreSrsImages: namespaces.hr_HR_CoreSrsImages,
    coreSrsPrimary: namespaces.hr_HR_CoreSrsPrimary,
  },
  hu_HU: {
    coreSrsImages: namespaces.hu_HU_CoreSrsImages,
    coreSrsPrimary: namespaces.hu_HU_CoreSrsPrimary,
  },
  it_IT: {
    coreSrsImages: namespaces.it_IT_CoreSrsImages,
    coreSrsPrimary: namespaces.it_IT_CoreSrsPrimary,
    srsPrimary: namespacesPrimary.it_IT_srsPrimary,
  },
  ja_JP: {
    coreSrsImages: namespaces.ja_JP_CoreSrsImages,
    coreSrsPrimary: namespaces.ja_JP_CoreSrsPrimary,
  },
  ko_KR: {
    coreSrsImages: namespaces.ko_KR_CoreSrsImages,
    coreSrsPrimary: namespaces.ko_KR_CoreSrsPrimary,
  },
  nl_NL: {
    coreSrsImages: namespaces.nl_NL_CoreSrsImages,
    coreSrsPrimary: namespaces.nl_NL_CoreSrsPrimary,
    editorLocalization: namespaces.nl_NL_EditorLocalization,
    gridLocalization: namespaces.nl_NL_GridLocalization,
    uploadLocalization: namespaces.nl_NL_UploadLocalization,
    srsPrimary: namespacesPrimary.nl_NL_srsPrimary,
  },
  nn_NO: {
    coreSrsImages: namespaces.nn_NO_CoreSrsImages,
    coreSrsPrimary: namespaces.nn_NO_CoreSrsPrimary,
  },
  pl_PL: {
    coreSrsImages: namespaces.pl_PL_CoreSrsImages,
    coreSrsPrimary: namespaces.pl_PL_CoreSrsPrimary,
    editorLocalization: namespaces.pl_PL_EditorLocalization,
    gridLocalization: namespaces.pl_PL_GridLocalization,
    uploadLocalization: namespaces.pl_PL_UploadLocalization,
  },
  pt_BR: {
    coreSrsImages: namespaces.pt_BR_CoreSrsImages,
    coreSrsPrimary: namespaces.pt_BR_CoreSrsPrimary,
    editorLocalization: namespaces.pt_BR_EditorLocalization,
    gridLocalization: namespaces.pt_BR_GridLocalization,
    uploadLocalization: namespaces.pt_BR_UploadLocalization,
    srsPrimary: namespacesPrimary.pt_BR_srsPrimary,
  },
  pt_PT: {
    coreSrsImages: namespaces.pt_PT_CoreSrsImages,
    coreSrsPrimary: namespaces.pt_PT_CoreSrsPrimary,
    editorLocalization: namespaces.pt_PT_EditorLocalization,
    gridLocalization: namespaces.pt_PT_GridLocalization,
    uploadLocalization: namespaces.pt_PT_UploadLocalization,
  },
  ru_RU: {
    coreSrsImages: namespaces.ru_RU_CoreSrsImages,
    coreSrsPrimary: namespaces.ru_RU_CoreSrsPrimary,
    editorLocalization: namespaces.ru_RU_EditorLocalization,
    gridLocalization: namespaces.ru_RU_GridLocalization,
    uploadLocalization: namespaces.ru_RU_UploadLocalization,
    srsPrimary: namespacesPrimary.ru_RU_srsPrimary,
  },
  sv_SE: {
    coreSrsImages: namespaces.sv_SE_CoreSrsImages,
    coreSrsPrimary: namespaces.sv_SE_CoreSrsPrimary,
  },
  th_TH: {
    coreSrsImages: namespaces.th_TH_CoreSrsImages,
    coreSrsPrimary: namespaces.th_TH_CoreSrsPrimary,
  },
  tr_TR: {
    coreSrsImages: namespaces.tr_TR_CoreSrsImages,
    coreSrsPrimary: namespaces.tr_TR_CoreSrsPrimary,
  },
  uk_UA: {
    editorLocalization: namespaces.uk_UA_EditorLocalization,
    gridLocalization: namespaces.uk_UA_GridLocalization,
  },
  vi_VN: {
    coreSrsImages: namespaces.vi_VN_CoreSrsImages,
    coreSrsPrimary: namespaces.vi_VN_CoreSrsPrimary,
  },
  zh_CN: {
    coreSrsImages: namespaces.zh_CN_CoreSrsImages,
    coreSrsPrimary: namespaces.zh_CN_CoreSrsPrimary,
    editorLocalization: namespaces.zh_CN_EditorLocalization,
    gridLocalization: namespaces.zh_CN_GridLocalization,
    uploadLocalization: namespaces.zh_CN_UploadLocalization,
    srsPrimary: namespacesPrimary.zh_CN_srsPrimary,
  },
  zh_TW: {
    coreSrsImages: namespaces.zh_TW_CoreSrsImages,
    coreSrsPrimary: namespaces.zh_TW_CoreSrsPrimary,
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US',
    lng: 'en_US',
    debug: false,
    ns: ['coreSrsPrimary', 'srsPrimary'],
    resources,
    react: {
      useSuspense: false,
    },
  })
