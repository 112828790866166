import LeftMenuComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.leftMenu.component'
import {
  useAppDispatch,
  useAppSelector,
} from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import { Fragment, useEffect } from 'react'
import {
  discard,
  marketingContent,
  promote,
  resetStatus,
  status as reduxStatus,
} from 'srs.sharedcomponents/lib/esm/redux/slices/webPartEditorSlice'
import { IZone } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/srs.zone.model'
import CmsButtonsComponent from 'srs.sharedcomponents/lib/esm/components/webPartEditor/srs.cmsButtons.component'
import { getWelcome } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import {
  CMSEditorTypes,
  stateStatusWebPartEditor,
  WelcomeZonesNames,
} from 'srs.sharedcomponents/lib/esm/utils/enums'
import {
  fillRotatingImages,
  fillSingleImage,
  getImgValues,
  getRotatingImagesValues,
  stringToBoolean,
} from 'srs.sharedcomponents/lib/esm/utils/helpers'
import { HTMLElement, parse } from 'node-html-parser'
import {
  rotatingImagesTemplate,
  singleImageTemplate,
} from 'srs.sharedcomponents/lib/esm/utils/constants'
import { IImagePoco } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/IImagePoco'
import htmlParser from 'html-react-parser'
import i18n from 'i18next'
import { useTranslation as a} from 'react-i18next'
import { i18nNamespaces as namespace } from './i18n/i18n'
import DOMPurify from 'dompurify'


interface IWelcomeComponentProps {
  isLeftSideMobileMenuExpand?: boolean
  toggleLeftSideMobileMenu?: () => void
}

const WelcomeComponent = (props: IWelcomeComponentProps) => {
  const leftNavEnabled = stringToBoolean(
    process.env.REACT_APP_LEFT_NAVIGATION_ENABLED,
  )
  const { t } = a('')
  const welcome = useAppSelector((state) => state?.welcome?.welcome)
  const cmsEditPermission = useAppSelector(
    (state) =>
      state?.user?.user?.permissions?.includes('CMS') &&
      state?.webPartEditor?.editContentToggle,
  )
  const isNARegion = useAppSelector(
    (state) =>
      state?.user?.user?.countryId === 840 || state?.user?.user?.countryId === 124)
  const webPartContent = useAppSelector(marketingContent)
  const isContentEditorEnabled = cmsEditPermission && webPartContent
  const dispatch = useAppDispatch()
  const reduxStateStatus = useAppSelector(reduxStatus)
  const getWebPartZoneByName = (zoneName: string): IZone | undefined => {
    return webPartContent?.zones?.find((zone) => zone.name === zoneName)
  }
  const getWelcomeZoneContentByName = (zoneName: string): string => {
    let currentZone = welcome?.zones?.find((zone) => zone.name === zoneName)
    if (currentZone) {
      let tempHtml = parse(currentZone.content)
      let contentType = ''
      try {
        contentType =
          (tempHtml.firstChild as HTMLElement).attributes['data-ContentType'] ||
          (tempHtml.firstChild as HTMLElement).attributes['data-contenttype']
        const imagesCount = tempHtml.getElementsByTagName('img').length
        if (imagesCount === 1) {
          contentType = CMSEditorTypes.SingleImageLink
        }
        if (imagesCount > 1) {
          contentType = CMSEditorTypes.RotatingImages
        }
      } catch (e) { }
      let template = ''
      switch (contentType) {
        case CMSEditorTypes.SingleImageLink: {
          let tempImgPoco = getImgValues(tempHtml)
          template = singleImageTemplate
          copyImagePropertiesAsNew(tempImgPoco)
          return fillSingleImage(template, tempImgPoco)
        }

        case CMSEditorTypes.RotatingImages: {
          let rotImages = getRotatingImagesValues(tempHtml)
          template = rotatingImagesTemplate
          // Removes leftover arrows from old implementation of the cms
          rotImages = rotImages.filter(
            (image) => !isAbsolutePath(image.imageUrl),
          )
          rotImages.forEach((image, index) => {
            rotImages[index] = copyImagePropertiesAsNew(image)
          })
          return fillRotatingImages(template, rotImages)
        }

        default: {
          return currentZone.content
        }
      }
    }
    return ''
  }
  const isAbsolutePath = (path: string) => {
    return path?.charAt(0) === '/'
  }
  const copyImagePropertiesAsNew = (image: IImagePoco) => {
    image.newCurrentLinkType = image.currentLinkType
    image.newCurrentLink = image.currentLink
    image.newLinkOpenNewTab = image.linkOpenNewTab
    return image
  }
  const onPromote = (zoneName: string) => {
    dispatch(
      promote({
        TempContent: getWebPartZoneByName(zoneName)?.tempContent as string,
        ContentType: 1,
        TempFolderPath: getWebPartZoneByName(zoneName)
          ?.tempFolderPath as string,
      }),
    )
  }
  useEffect(() => {
    if (reduxStateStatus === stateStatusWebPartEditor.promoteFulfilled) {
      dispatch(getWelcome())
      dispatch(resetStatus())
    }
  }, [reduxStateStatus])
  const onDiscard = (zoneName: string) => {
    dispatch(
      discard({
        TempFolderPath: getWebPartZoneByName(zoneName)
          ?.tempFolderPath as string,
      }),
    )
  }
  if (!welcome || welcome.anonymousId === '') return <></>
  return (
    <Fragment>
      <Container>
        <main id="main" role="main" className="pb-3">
          <Row>
            {leftNavEnabled && (
              <Col
                className={`${props.isLeftSideMobileMenuExpand ? '' : 'd-none '
                  } d-xl-block d-lg-block`}
              >
                <LeftMenuComponent
                  items={welcome.leftCatalog}
                  toggleLeftSideMobileMenu={props.toggleLeftSideMobileMenu}
                />
              </Col>
            )}
            <Col
              md={12}
              lg={leftNavEnabled ? 9 : 12}
              xl={leftNavEnabled ? 9 : 12}
            >
              <Row className="welcome row justify-content-center">
                <div
                  className="col-md-7 col-sm-6 first-image position-relative"
                  id="welcomeZoneImage"
                >
                  {getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)
                    ?.tempContent
                    ? htmlParser(
                      getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)?.tempContent as string,
                    )
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.WelcomeZone))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent
                      zone={getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)}
                      onPromote={onPromote}
                      onDiscard={onDiscard}
                    />
                  )}
                </div>
                <div
                  className="col-6 col-md-5 col-sm-6 second-image position-relative img-fluid p-md-5"
                  id="mainZoneImage"
                >
                  {getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent
                    ? htmlParser(
                      getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent as string,
                    )
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.MainZone))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent
                      zone={getWebPartZoneByName(WelcomeZonesNames.MainZone)}
                      onPromote={onPromote}
                      onDiscard={onDiscard}
                    />
                  )}
                </div>

                <div className="col-12">

                  <div className="row justify-content-center">
                    <div className="col-12 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                      <div
                        className="row mx-0 bg-light border-top border-bottom py-1 align-items-center py-xxl-2"
                      >
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center"
                          id="additionalZone1Image"

                        >
                          <a
                            href="esst?TAG=Specialtools"
                            target="_self"
                            data-contenttype="SingleImageLink"

                          >
                            <div style={{ color: "#959e14", fontWeight: "600" }} className="text-uppercase">
                            {t(`txtSpecialTools`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center"
                          id="additionalZone2Image"
                        >
                          <a
                            href = {process.env.REACT_APP_MINIMUM_STANDARDURL}
                            target="_blank"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(t(`txtMarketSpecific`, {
                                  ns: namespace.srsPrimary,
                              }))
                          }}>
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center"
                          id="additionalZone3Image"
                        >
                          <a
                            href="ProductListCatalog?CatID=325703"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtDealerEquipment`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center"
                          id="additionalZone4Image"
                        >
                          {isNARegion?
                          <a
                            href="/ToolOrganization"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtToolOrganization`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                          :<a
                            href="ProductListCatalog?CatID=326424"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtBodyPgm`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-block d-md-none text-center bg-light border-top border-bottom py-2 py-md-0"
                          id="additionalZone1Image"

                        >
                          <a
                            href="esst?TAG=Specialtools"
                            target="_self"
                            data-contenttype="SingleImageLink"

                          >
                            <div style={{ color: "#959e14", fontWeight: "600" }} className="text-uppercase">
                            {t(`txtSpecialTools`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-none d-sm-block d-md-none text-center bg-light border-top border-bottom"
                          id="additionalZone2Image"
                        >
                          <a
                            href= {process.env.REACT_APP_MINIMUM_STANDARDURL}
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                           <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(t(`txtMarketSpecific`, {
                                  ns: namespace.srsPrimary,
                              }))
                          }}>
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center position-relative"
                          id="additionalZone1Image"
                        >

                          {getWebPartZoneByName(WelcomeZonesNames.Zone1)?.tempContent
                            ? htmlParser(
                              getWebPartZoneByName(WelcomeZonesNames.Zone1)?.tempContent as string,
                            )
                            : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone1))}
                          {isContentEditorEnabled && (
                            <CmsButtonsComponent
                              zone={getWebPartZoneByName(WelcomeZonesNames.Zone1)}
                              onPromote={onPromote}
                              onDiscard={onDiscard}
                            />
                          )}
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-block d-sm-none text-center bg-light border-top border-bottom py-2 py-sm-0"
                          id="additionalZone2Image"
                        >
                          <a
                            href = {process.env.REACT_APP_MINIMUM_STANDARDURL}
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                           <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(t(`txtMarketSpecific`, {
                                  ns: namespace.srsPrimary,
                              }))
                          }}>
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center four-image position-relative"
                          id="additionalZone2Image"
                        >
                          {getWebPartZoneByName(WelcomeZonesNames.Zone2)?.tempContent
                            ? htmlParser(
                              getWebPartZoneByName(WelcomeZonesNames.Zone2)?.tempContent as string,
                            )
                            : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone2))}
                          {isContentEditorEnabled && (
                            <CmsButtonsComponent
                              zone={getWebPartZoneByName(WelcomeZonesNames.Zone2)}
                              onPromote={onPromote}
                              onDiscard={onDiscard}
                            />
                          )}
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-block d-md-none text-center bg-light border-top border-bottom py-2 py-md-0"
                          id="additionalZone3Image"
                        >
                          <a
                            href="ProductListCatalog?CatID=325703"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtDealerEquipment`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-none d-sm-block d-md-none text-center bg-light border-top border-bottom py-sm-2"
                          id="additionalZone4Image"
                        >
                          {isNARegion?
                          <a
                            href="/ToolOrganization"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtToolOrganization`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                          :<a
                            href="ProductListCatalog?CatID=326424"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtBodyPgm`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>}
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center five-image position-relative"
                          id="additionalZone3Image"
                        >
                          {getWebPartZoneByName(WelcomeZonesNames.Zone3)?.tempContent
                            ? htmlParser(
                              getWebPartZoneByName(WelcomeZonesNames.Zone3)?.tempContent as string,
                            )
                            : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone3))}
                          {isContentEditorEnabled && (
                            <CmsButtonsComponent
                              zone={getWebPartZoneByName(WelcomeZonesNames.Zone3)}
                              onPromote={onPromote}
                              onDiscard={onDiscard}
                            />
                          )}
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center d-block d-sm-none text-center bg-light border-top border-bottom py-2 py-sm-0"
                          id="additionalZone4Image"
                        >
                          {isNARegion?
                          <a
                            href="/ToolOrganization"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtToolOrganization`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>
                          : <a
                            href="ProductListCatalog?CatID=326424"
                            target="_self"
                            data-contenttype="SingleImageLink"
                          >
                            <div className="text-uppercase" style={{ color: "#959e14", fontWeight: "600" }}>
                            {t(`txtBodyPgm`, { ns: namespace.srsPrimary })}
                            </div>
                          </a>}
                        </div>
                        <div
                          className="col-12 col-md-3 col-sm-6 text-center six-image position-relative"
                          id="additionalZone4Image"
                        >
                          {getWebPartZoneByName(WelcomeZonesNames.Zone4)?.tempContent
                            ? htmlParser(
                              getWebPartZoneByName(WelcomeZonesNames.Zone4)?.tempContent as string,
                            )
                            : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone4))}
                          {isContentEditorEnabled && (
                            <CmsButtonsComponent
                              zone={getWebPartZoneByName(WelcomeZonesNames.Zone4)}
                              onPromote={onPromote}
                              onDiscard={onDiscard}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </main>
      </Container>

    </Fragment>
  )
}
export default WelcomeComponent
function useTranslation(arg0: string): { t: any } {
  throw new Error('Function not implemented.')
}

