import { globalTheme } from 'srs.sharedcomponents/lib/esm/styles/config'

const jlrThemeColor = "#959E14";
export const customTheme = {
  colors: {
    ...globalTheme.colors,
    primary: jlrThemeColor,
    continueShoppingButton: '#f2f2f2',
    sideBarActiveBackGroundColor: '#f2f2f2',
    buttonTextColor: '#f2f2f2',
    menuButton: '#000',
    sideBarActiveTextColor: '#f2f2f2',
    loginButtonFont: '#f2f2f2',
    hoverMenuButton:'#000',
    mobileButtonColor: '#000'
  },
}
