import { Container } from 'react-bootstrap'
import SpecificWebPartComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.specificWebPart.component'

interface IMinimumStandardProps {
    isLeftSideMobileMenuExpand?: boolean
    toggleLeftSideMobileMenu?: () => void
}

const MinimumStandardComponent = (props: IMinimumStandardProps) => {
    return (

        <div>
            <Container className='text-center'>
                <div style={{ display: "inline-block", border: "1px solid #000000", borderRadius: "10px", margin: "20px 0", textAlign: "left" }}>
                    <SpecificWebPartComponent isLeftSideMobileMenuExpand={props.isLeftSideMobileMenuExpand} toggleLeftSideMobileMenu={props.toggleLeftSideMobileMenu}
                        zoneName='ToolOrganization' />
                </div>
            </Container>
        </div>
    )
}
export default MinimumStandardComponent